import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default () => (
  <Layout>
    <SEO title="Příchod nových a vymírání původních biologických druhů" />
    <h1>Příchod nových a vymírání původních biologických druhů</h1>
<p>Příchod <strong>nových biologických druhů</strong> a <strong>vymírání druhů původních</strong> se dělo v&nbsp;přírodě vždy a přirozeně, většina těchto dějů se však v&nbsp;holocénu udála působením člověka. Například 99 % současného vymírání druhů je důsledkem lidské činnosti, celosvětově od roku 1600 pak do začátku 21. století vymřelo zhruba 2,1 % savců a 1,3 % ptáků.</p>
<hr />
<h2>Příčiny příchodu a mizení druhů</h2>
<p>Z&nbsp;hlediska míry vlivu člověka lze vymezit následující příčiny příchodu či mizení biologických druhů:</p>
<ul>
<li><strong>přírodní přirozené</strong></li>
<li><strong>změna prostředí člověkem</strong></li>
<li><strong>nechtěné zavlečení či zahubení</strong></li>
<li><strong>cílené zavedení či vyhubení</strong></li>
</ul>
<p><strong>Přírodní přirozený příchod</strong> je poměrně vzácný, často jen nelze lidský vliv prokázat. Příkladem přirozeného příchodu je svízelka lysá, která domigrovala ve 2. polovině 20. století z&nbsp;moravských Karpat přes Krkonoše až do západních Čech.</p>
<p>Častější je však u pohyblivých obratlovců, kupříkladu zvonohlík zahradní se dostal během 19. století z&nbsp;Evropy jižní do střední. Hrdlička zahradní k&nbsp;nám pak domigrovala z&nbsp;Balkánu v&nbsp;50. letech 20. století. Ve stejném období na naše území domigroval ze Sýrie strakapoud zahradní. Na Břeclavsku se pak v&nbsp;roce 2008 objevil šakal obecný pocházející z&nbsp;jižní Evropy.</p>
<p>Tyto druhy pak můžou vytvořit na daných stanovištích konkurenci, která způsobí vymizení jiného druhu. V&nbsp;případě pohyblivých obratlovců je však možné, že tyto druhy pouze využily se zpožděním otevřené krajiny vytvořené člověkem, a nejde tak o zcela přirozený příchod.</p>
<p>Hojně k&nbsp;nám také v&nbsp;současnosti migruje létající hmyz z&nbsp;jihu. Zde je ale nasnadě otázka, zda to není vlivem klimatické změny.</p>
<p><strong>Změna prostředí člověkem</strong> často podmiňuje změny v&nbsp;detailu, například migraci v&nbsp;měřítku krajů. To může být příklad nitrofilních druhů, kdy expandují do stanovišť nově obohacených dusíkem. Jde často o tzv. apofyty, typickým zástupcem je expandující kopřiva. Podobně to funguje u halofilních druhů (zasolování podél cest, zbochlanec oddálený) a kalcifilních druhů (prášení vápnem).</p>
<p>Migrace může však probíhat i z&nbsp;daleka, může jít o polní druhy (plevel koukol, vlčí mák) či například křečka. Rovněž jde i o myši a jiné druhy, které se rozšířily vlivem růstu zemědělských vesnic. V&nbsp;současnosti pak jde hlavně o tropické druhy, které přežívají v&nbsp;bytech – mravenci faraóni či pavouk třesavka velká. Tyto nesnášejí zimní mrazy a bez vytápěných obydlí by zde nemohly přežít.</p>
<p>Druhy na změněných stanovištích mohou pak mizet jednak kvůli příchodu druhů nových, ale též také kvůli přímé likvidaci stanovišť (příklad přirozených štěrkových a písečných břehů na Vltavě), degradací stanovišť (přehnojení, příklad orchidejí), globální změnou klimatu či fragmentací stanovišť. Poslední jmenované je nezřetelné, a proto nebezpečné riziko. Ve fragmentovaných částech jsou izolované mikropopulace, které nejsou životaschopné.</p>
<p>Případné <strong>zavlečené či vysazené</strong> druhy (tj. <strong>introdukované</strong>), lze rozlišit na ty, které jsou zavedeny <strong>cíleně</strong>, pro nějaký užitek (<strong>domestikované druhy</strong>), a na ty, které jsou uvedeny <strong>nechtěně</strong> a často jdou proti zájmům člověka (<strong>synantropní druhy</strong>).</p>
<p>Mezi synantropní druhy patří různé polní plevely, například vlčí mák. Jelikož jde o rostlinu introdukovanou před rokem 1500, mluvíme o <strong>archeofytu</strong>. Dále sem patří i současné invazivní rostliny, například bolševník a netýkavky. Těmto říkáme <strong>neofyty</strong>, protože byly na naše území uvedeny po roce 1500. Analogicky lze zvířata rozdělit na <strong>archeozou a neozou</strong>.</p>
<p>Zajímavým případem introdukce je potkan, který se k&nbsp;nám rozšířil z&nbsp;východní Asie a prakticky vyhubil naše domácí krysy. Tím se ovšem přestal i šířit mor. Dnes jsou krysy jen v&nbsp;severozápadních Čechách.</p>
<hr />
<h2>Invazivní a expanzivní druhy</h2>
<p><strong>Invazivní rostliny </strong>jsou druhy cizí, které sem byly dovezeny, popřípadě na naše území vstoupily samovolně. Zde pak velmi expandují, protože nemají přirozenou konkurenci. Část invazivních druhů se nemusí zhruba 40 až 50 let šířit, poté ale může dojít k&nbsp;jejich adaptaci (zdvojení řady chromozomů) a expanzi. Invazivně se v&nbsp;našich podmínkách chová zhruba 90 z&nbsp;1000 cizích rostlin.</p>
<p><strong>Expanzivní rostliny (tj. apofyty)</strong> jsou sice původem naše, ale dřív byly rozšířeny jen na velmi málo stanovištích a nyní expandují intenzivně na jiná. Tyto rostliny typicky využily změny krajiny člověkem, příkladem může být <strong>kopřiva</strong>. Ta je extrémně nitrofilní druh, který byl původně vzácný. Vlivem nitrifikace krajiny se rozšířil téměř všude, dokonce až do hor.</p>
<p>Invazivní rostliny i apofyty představují pro krajinu problém. Ostatní, původní druhy jsou jimi totiž vytlačovány. Vyvstává však otázka, <u>jak vlastně definovat původní druh?</u></p>
<p>Například taková kopřiva je původním druhem na území ČR, ale expanduje na jiná stanoviště, což současná legislativa neřeší. Je zde i problém historie, co když druh, který dnes považujeme za původní, byl ve skutečnosti třeba před 2 000 lety zavlečen do naší krajiny Římany? Problém je i u definování modřínu, zda je původní (sudetský ekotyp) či nepůvodní (alpský ekotyp, rozšířenější).</p>
<hr />
<h2>Introdukce rostlin a živočichů</h2>
<p><strong>Introdukce </strong>je vysazení druhů na nějaké místo, a to mimo jejich původní areál. Častá byla cílená introdukce do chovů (krocan, činčila, norek, papoušek, páv) a skleníků. Dokud jsou organismy zavřené v&nbsp;chovech a sklenících, je to z&nbsp;hlediska ekologie v&nbsp;pořádku. Nicméně některá zvířata a rostliny se dostaly do volné krajiny. Příkladem zvířat utečených z&nbsp;chovů a zahrad jsou <strong>ondatra, nutrie, barvínek, bolševník či netýkavky.</strong></p>
<p><strong>Ondatra</strong> je původně americký druh hlodavce, který utekl ze zámeckého parku v&nbsp;Dobříši v&nbsp;roce 1905. Již za 22 let byl rozšířen v&nbsp;celé střední Evropě.</p>
<p><strong>Barvínek</strong> se k&nbsp;nám dostal pravděpodobně v&nbsp;době šíření křesťanství, dával se totiž na hroby a do krajiny se rozšířil právě ze hřbitovů. Jde o stále zelený, plazivý keřík s&nbsp;fialovým květem, příliš se ale nešíří a nechová se invazivně.</p>
<p><strong>Bolševník velkolepý</strong> dělá problémy především ve Slavkovském lese, kde se chová invazivně. Do Čech byl přivezen z&nbsp;Asie pro knížete Metternicha jako okrasná květina, do krajiny se dostal ze zahrady zámku Kynžvart.</p>
<p>U nás existuje jeden původní druh <strong>netýkavky</strong> (netýkavka nedůtklivá) a 2 invazivní. První invazivkou je <strong>netýkavka malokvětá</strong>, ta unikla ze zahrad ve Vratislavi, Berlíně a zámeckého parku Březina u Rokycan. Její masové rozšíření je kolem roku 1900 zaznamenáno v&nbsp;okolí Prahy, na Moravu se dostává v&nbsp;roce 1912. V&nbsp;současnosti se adaptuje a leze více do hor, stále se zvyšuje její výška. Jde o nejběžnější invazivní rostlinu, přežívá zimu v&nbsp;semenech (terofyt) a intenzivněji se šíří v&nbsp;pozměněných lesích a narušených místech podél cest.</p>
<p><strong>Netýkavka žláznatá</strong> je taktéž terofyt, kvete fialově koncem srpna a roste v&nbsp;okolí řek. Uniká ze zahrady a v&nbsp;roce 1900 je prvně zjištěna ve volné přírodě, a to v&nbsp;okolí Turnova. Po roce 1945 překonává rozvodí a objevuje se u Letovic, za dalších 15 let se dostala v&nbsp;semenech po dně Svitavy až do Brna.</p>
<p>Při introdukci je potřeba velká míra <strong>zodpovědnosti</strong>, především pak u cíleného vysazování do volné přírody. S&nbsp;introdukcí souvisí i problematika <strong>GMO.</strong> Z&nbsp;hlediska kvality potravin jsou v&nbsp;pořádku, nicméně jsou pěstovány k&nbsp;vysoké odolnosti. Existuje obava, že některá geneticky modifikovaná plodina se začne silně šířit ve volné krajině a začne likvidovat domácí flóru. Například modifikovaná rajčata se pomalu šíří řekami, usazují se zvolna na štěrkových lavicích – situaci je třeba monitorovat, aby nedošlo například po 40 letech k&nbsp;jejich náhlé adaptaci a expanzi.</p>
<hr />
<h2>Omezení šíření nepůvodních druhů</h2>
<p>Nejúčinnější je prevence, tzn. dané druhy vůbec nevysazovat a pokud možno využít původních druhů. Místo tújí lze například využít domácí jalovec. Pokud však již nějaký druh cíleně introdukujeme, je nutné ho sledovat a kontrolovat.</p>
<p>U dvoudomých rostlin lze pak sázet na stanoviště pouze mužské, nebo ženské rostliny. Dále, pokud nebudeme narušovat povrch, nedojde k&nbsp;mineralizaci, uvolnění živin a neotevřeme tím cestu invazivním rostlinám. Pokud již povrch musím porušit, musím ho rychle rekultivovat a ideálně na místo vrátit drny.</p>
<p>Pokud se již nějaký druh šíří, je na místě ho likvidovat. To však často není proveditelné a lze tak pouze omezovat šíření, mluvíme pak o <u>tlumení škodné</u>. Při tom je nutné spolupracovat s&nbsp;botaniky a zoology, kteří mají znalost životních cyklů a ví, kdy je nejefektivnější daný druh omezovat. Po zásahu se může zdát, že je problém vyřešen, místo zásahu je ale třeba dlouho sledovat a kontrolovat – obvykle totiž nějaké semínko či kořen přežije.</p>
<p>U <strong>nitrofilních rostlin</strong> je vhodné vegetaci 5x požnout, vyvážet biomasu, aby došlo k&nbsp;poklesu dusíku v&nbsp;půdě, a vysít původní druhy. U <strong>kvetoucích rostlin </strong>je vhodné zabránit jejich plození, je nutno je požnout dříve, než vykvetou.</p>
<p><strong>Dřeviny</strong>, například akát, lze hubit zastíněním či kroužkováním. V&nbsp;dubnu se oseká kroužek kůry v&nbsp;dostatečné výšce, nesmí zůstat ani vlákno. V&nbsp;létě pak nemůže stáhnout asimiláty do kořenů a na jaře pak má nedostatek živin. Pokud se toto opakuje zhruba 5 let, dojde k&nbsp;vyčerpání stromu. Na některé druhy keřů lze nasadit kozy.</p>
<p>Na oddenkové rostliny lze využít chemii, či je nutno je vykopat. V&nbsp;případě chemické likvidace používáme herbicidy. Nejznámější Roundup je totálním herbicidem, který likviduje bez rozdílu vše. Dále existují úzkospektrální herbicidy (úzká skupina rostlin, na plevel) a širokospektrální herbicidy (širší skupina rostlin).</p>
<p>Na živočichy a jejich sídla pak lze použít desinsekci (včetně juvenoidů, viz příklad mravenců v&nbsp;chemickém boji) a deratizaci. V&nbsp;krajině se přistupuje k&nbsp;desinsekci, pastím a odlovům, případně k&nbsp;zavedení konkurence, predátorů, případně i parazitů a chorob.</p>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Příchod nových a vymírání původních biologických druhů</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/pojmy-lesnictvi/"><button className="inv">&larr; Vybrané pojmy lesnictví</button></Link>
    <Link to="/ekologie-zivotni-prostredi/repatriace-navrat-introdukce/"><button className="inv">Repatriace, návrat a introdukce druhů &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
